import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Data from "../Data.json";
import { MenuState } from "../context/MenuContext";
import Cart from "../components/Cart";
import CustomHeader from "../components/CustomHeader";
import { MdContactSupport } from "react-icons/md";

const MenuDetail = () => {
  const navigate = useNavigate();
  const {
    cartResponse,
    setCartResponse,
    modifiersResponse,
    setModifiersResponse,
    cartData,
    setCartData,
  } = MenuState();

  const [show, setShow] = useState(false);

  const { state } = useLocation();
  const detail = state && state.detail;

  const modiferData = Data.modifiers;
  const handleClose = () => setShow(false);

  // const handleShow = (newData) => {
  //   const updatedCart = [...cartResponse, newData];
  //   setCartResponse(updatedCart);
  //   console.log(cartResponse, "cartResponse");
  // };

  const handleShow = (newData) => {
    let itemExists = false;

    const updatedCart = cartResponse?.map((item) => {
      if (item.id === newData.id) {
        itemExists = true;
        const newQuantity = (item.quantity || 1) + 1;
        const newTotalPrice = item.originalPrice * newQuantity;
        return { ...item, quantity: newQuantity, totalPrice: newTotalPrice };
        //return { ...item, quantity: (item.quantity || 1) + 1 };
      }
      return item;
    });

    if (!itemExists) {
      updatedCart.push({
        ...newData,
        quantity: 1,
        originalPrice: newData.price,
        totalPrice: newData.price,
      });
    }

    setCartResponse(updatedCart);
    console.log(cartResponse, "cartResponse");
  };

  const addModifier = (modifierData, itemId) => {
    if (cartData.cartItems.length === 0) {
      alert("Please add an item to the cart before adding modifiers.");
      return;
    }

    const updatedCartItems = cartData?.cartItems?.map((item) => {
      if (item.Id === itemId) {
        // Found the item, now add the modifier to it
        const updatedModifiers = item.Modifiers
          ? [
              ...item.Modifiers,
              {
                Id: modifierData.id,
                Title: modifierData.title,
                Price: modifierData.price,
              },
            ]
          : [
              {
                Id: modifierData.id,
                Title: modifierData.title,
                Price: modifierData.price,
              },
            ];

        // Update the TotalPrice of the item to include the modifier's price
        const updatedTotalPrice = item.TotalPrice + modifierData.price;

        // Return the updated item
        return {
          ...item,
          Modifiers: updatedModifiers,
          TotalPrice: updatedTotalPrice,
        };
      }
      // Return the item as is if not the target item
      return item;
    });

    // Update the cartData with the updated cart items
    setCartData((prevData) => ({
      ...prevData,
      cartItems: updatedCartItems,
      // Also, update the total cart price to include the modifier's price
      total_Price: updatedCartItems.reduce(
        (total, item) => total + item.TotalPrice,
        0
      ),
    }));

    setShow(true);
    // const updatedModifier = [...modifiersResponse, modifierData];
    // setModifiersResponse(updatedModifier);
    // setShow(true);
  };

  console.log(modifiersResponse, "modifiersResponse");
  return (
    <>
      <Cart show={show} onHide={handleClose} />

      <div className="menu-detail">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <CustomHeader
                onBackClick={() => navigate(-1)}
                onCartIconClick={() => setShow(true)}
                cartQty={cartResponse.length}
              />
              <img
                style={{ height: "300px", width: "100%" }}
                // src={detail?.image}
                src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                alt="img"
              />

              <div className="pt-2">
                <h4>{detail?.itemName}</h4>
                <p className="m-0">{detail?.description}</p>
              </div>

              <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <span>Small (1 Kcal)</span> */}
                  <span>{detail?.price}</span>
                  <button
                    onClick={() => handleShow(detail)}
                    className="modifer-btn"
                  >
                    +
                  </button>
                </div>
                <hr />
              </div>

              <h3>Modifers</h3>
              {modiferData?.map((x, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{x.title}</span>
                      <span>{x.price}</span>
                      <button
                        className="modifer-btn"
                        onClick={() => addModifier(x, detail?.id)}
                      >
                        +
                      </button>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuDetail;
