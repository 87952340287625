import React, { createContext, useContext } from "react";
import { useState } from "react";
const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [cartResponse, setCartResponse] = useState([]);
  const [modifiersResponse, setModifiersResponse] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [cartData, setCartData] = useState({
    cartItems: [],
    total_Price: 0,
  });

  return (
    <MenuContext.Provider
      value={{
        cartResponse,
        setCartResponse,
        modifiersResponse,
        setModifiersResponse,
        orderDetail,
        setOrderDetail,
        cartData,
        setCartData,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const MenuState = () => {
  return useContext(MenuContext);
};

export default MenuProvider;
