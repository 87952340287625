export const BASE_URL = "http://portal.diner-tab.com/api/";

//Testing Url
//export const BASE_URL = "https://030m8ml6-7104.uks1.devtunnels.ms/";

export const API_URL = {
  GET_POS_DATA: "Getorderfromother/getposdata/",

  SAVING_RUNNING_ORDER: "Getorderfromother/SaveRunningOrder/",
};
