import React, { useState } from "react";

const MenuStart = () => {
  const [show, setShow] = useState(false);
  const tables = [
    {
      QRGenerator: "QR generator 1",
      table: "table 1",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 2",
      table: "table 2",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 3",
      table: "table 3",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 4",
      table: "table 4",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 5",
      table: "table 5",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 6",
      table: "table 6",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 7",
      table: "table 7",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 8",
      table: "table 8",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
    {
      QRGenerator: "QR generator 9",
      table: "table 9",
      URL: "https://app.diners-tab.com/menustart?resUserName=RTXFusionFare&TableNumber=1",
      QRImage: require("../Assets/Images/download.png"),
      Download: "Download",
    },
  ];

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div className="container-fluid px-5">
      <h2 className="text-center py-5">Dine-in QR Menu Configuration</h2>
      <p>QR Settings</p>
      <div className="row d-flex px-5 justify-content-center">
        {tables.map((table, index) => (
          <div className="col-md-4 mt-2" key={index}>
            <div className="inner px-4 py-3 border border-dark rounded">
              <h5 className="text-center">{table.QRGenerator}</h5>
              {show === true ? (
                <div className="d-flex align-items-center">
                  <img className="custom-QR" alt="image" src={table.QRImage} />
                  <div className="ms-3">
                    <h6>URL:</h6>
                    <p>{table.URL}</p>
                  </div>
                </div>
              ) : null}

              <div className="d-flex justify-content-center">
                <button onClick={handleClick} className="mt-2 QR-btn rounded">
                  {table.table}
                </button>
                {show === true ? (
                  <button className="mt-2 QR-btn ms-3 rounded">
                    {table.Download}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuStart;
