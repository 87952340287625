import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemCards from "./ItemCards";
import CustomHeader from "./CustomHeader";
import { MenuState } from "../context/MenuContext";
import Cart from "./Cart";

import { API_URL, BASE_URL } from "../global/Constant";
import apiFunctions from "../global/GlobalFunction";

const SectionSlider = () => {
  const [showItems, setShowItems] = useState(false);
  const [sectionId, setSectionID] = useState();
  const [show, setShow] = useState(false);
  const [posData, setPosData] = useState();

  useEffect(() => {
    getPosData();
  }, []);

  async function getPosData() {
    try {
      let getPosData = await apiFunctions.GET_REQUEST(
        BASE_URL + API_URL.GET_POS_DATA
      );
      let res = getPosData?.data;

      setPosData(res);
      //console.log(posData, "pos data");
    } catch (err) {
      console.log("An error occurred while fetching menus", err.message);
    }
  }

  const foodGroups = posData?.foodGroups;

  const handleClose = () => setShow(false);

  const { cartResponse } = MenuState();

  const showSectionDetail = (id, index) => {
    setSectionID(id, index);
    setShowItems(true);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="section-slider">
        <Cart show={show} onHide={handleClose} />
        <div className="container-fluid py-3">
          <div className="row">
            <div className="first col-lg-4 px-lg-5">
              <CustomHeader
                onCartIconClick={() => setShow(true)}
                cartQty={cartResponse.length}
              />
              <div className="text-center">
                <h4>Menu</h4>
                <p className="pt-4">Table # 1</p>
              </div>

              <Slider {...settings}>
                {foodGroups?.map((x, index) => {
                  return (
                    <div
                      onClick={() => showSectionDetail(x.id, index)}
                      className="d-flex justify-content-center flex-column align-items-center pt-4"
                      key={index}
                    >
                      {/* <img className="img-fluid" src={x.image} alt="img" /> */}
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                        alt="img"
                      />
                      <p className="pt-1 m-0">{x.groupName}</p>
                    </div>
                  );
                })}
              </Slider>

              {showItems ? <ItemCards sectionId={sectionId} /> : null}
            </div>

            <div className="col-lg-8 d-flex flex-column justify-content-center align-items-center">
              <div style={{ position: "absolute", top: "30%" }}>
                <h3 className="text-center">Restaurant Name</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionSlider;
