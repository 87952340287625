import React from "react";
import { CiUser } from "react-icons/ci";
import { MdOutlineIntegrationInstructions, MdPayment } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { SiVirustotal } from "react-icons/si";
import { useNavigate } from "react-router-dom";

const OrderDetail = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };
  return (
    <div className="order-detail">
      <div className="container d-flex flex-column justify-content-center vh-100">
        <div className="row px-lg-5">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src="https://app.diners-tab.com/static/media/thank-you.c919aad9e114fcc7d0c3.png"
              alt="img"
            />
          </div>

          <div className="col-md-6">
            <div className="inner shadow p-3">
              <div className="heading-box">
                <h3>Order Detial</h3>
              </div>

              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <CiUser size={20} color="white" />
                </div>
                <span className="ms-3">Customer Name : Abc Name</span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <MdOutlineIntegrationInstructions size={20} color="white" />
                </div>
                <span className="ms-3">
                  Special Instructions: My Instructions
                </span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <CiPhone size={20} color="white" />
                </div>
                <span className="ms-3">Phone Number: 03434309391</span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <MdPayment size={20} color="white" />
                </div>
                <span className="ms-3">Payment Method : Cash</span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <SiVirustotal size={20} color="white" />
                </div>
                <span className="ms-3">Sub Total : PKR 399</span>
              </div>
              <button onClick={goToHome} className="mt-4 global-btn">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
