import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import MenuPage from "./pages/MenuPage";
import MenuDetail from "./pages/MenuDetail";
import OrderPage from "./pages/OrderPage";
import MenuStart from "./pages/MenuStart";
import OrderDetail from "./pages/OrderDetail";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<MenuPage />} />
      <Route path="/menu-detail" element={<MenuDetail />} />
      <Route path="/order-page" element={<OrderPage />} />
      <Route path="/menu-start" element={<MenuStart />} />
      <Route path="/order-detail" element={<OrderDetail />} />
    </Routes>
  );
}

export default App;
