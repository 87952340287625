import React from "react";
import SectionSlider from "../components/SectionSlider";

const MenuPage = () => {
  return (
    <div className="menu-page">
      <SectionSlider />
    </div>
  );
};

export default MenuPage;
