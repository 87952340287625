import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { MenuState } from "../context/MenuContext";
import CustomHeader from "../components/CustomHeader";
import Cart from "../components/Cart";
import { useNavigate } from "react-router-dom";
import apiFunctions from "../global/GlobalFunction";
import { BASE_URL, API_URL, BASE_2 } from "../global/Constant";

const OrderPage = () => {
  const { orderDetail, setOrderDetail, cartResponse, setCartResponse } =
    MenuState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //console.log(orderDetail, "orderdetail");

  const navigate = useNavigate();

  const [customerName, setCustomerName] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  let customFont = {
    fontSize: "20px",
  };

  // const handleQuantityChange = (index, delta) => {
  //   const newCartData = { ...orderDetail };
  //   newCartData.cartItems[index].Quantity += delta;

  //   // Prevent negative quantity
  //   if (newCartData.cartItems[index].Quantity < 1) {
  //     newCartData.cartItems[index].Quantity = 1;
  //   } else {
  //     newCartData.cartItems[index].TotalPrice =
  //       newCartData.cartItems[index].ItemPrice *
  //       newCartData.cartItems[index].Quantity;
  //   }

  //   // Update subtotal
  //   newCartData.total_Price = newCartData.cartItems.reduce(
  //     (total, item) => total + item.TotalPrice,
  //     0
  //   );
  //   // console.log(newCartData, "lll");

  //   setOrderDetail(newCartData);
  // };

  const handleQuantityChange = (index, delta) => {
    const newCartData = { ...orderDetail };
    let item = newCartData.cartItems[index];

    // Increment or decrement the item quantity
    item.Quantity += delta;

    // Prevent negative quantity
    if (item.Quantity < 1) {
      item.Quantity = 1;
    }

    // Assuming Modifiers are structured as an array of objects {Id, Title, Price}
    // Calculate the total price of the modifiers, if any
    const modifiersPrice = item.Modifiers
      ? item.Modifiers.reduce((acc, modifier) => acc + modifier.Price, 0)
      : 0;

    // Update the TotalPrice of the item to include the item's base price times its quantity plus the total modifiers' price
    item.TotalPrice = item.ItemPrice * item.Quantity + modifiersPrice;

    // Update subtotal for the entire cart
    newCartData.total_Price = newCartData.cartItems.reduce(
      (total, item) => total + item.TotalPrice,
      0
    );

    // Update the order detail with the new cart data
    setOrderDetail(newCartData);
  };

  const handler = async () => {
    const data = {
      CustomerName: customerName,
      TableName: "T4",
      PhoneNumber: phoneNumber,
      Items: orderDetail.cartItems,
      PaymentMethod: paymentMethod,
      Instructions: specialInstructions,
      Subtotal: orderDetail.total_Price,
      Address: "abc",
      OrderType: 1,
      PickupTime: "2024-02-05T10:10:36",
      Note: "note",
      WaiterOrDriver: "Waiter",
    };

    try {
      let orderResponse;
      orderResponse = await apiFunctions.POST_REQUEST(
        BASE_URL + API_URL.SAVING_RUNNING_ORDER,
        data
      );
      console.log(orderResponse, "orderResponse");
      setCartResponse([]);
      navigate("/order-detail");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Cart show={show} onHide={handleClose} />
      <div className="container pt-2">
        <CustomHeader
          onBackClick={() => navigate(-1)}
          onCartIconClick={() => setShow(true)}
          cartQty={cartResponse.length}
          hideCart={"hidecart"}
        />
      </div>
      <div
        style={{ minHeight: "100vh" }}
        className="container d-lg-flex flex-column justify-content-center"
      >
        <div className="row">
          {orderDetail?.cartItems?.map((x, index) => {
            return (
              <>
                <div key={index} className="col-lg-8 px-lg-5">
                  <div className="d-lg-flex align-items-center justify-content-between shadow p-lg-4">
                    <div className="d-lg-flex align-items-center">
                      <img
                        style={{
                          height: "200px",
                          borderRadius: "10px",
                          width: "auto",
                        }}
                        // src={x.Image}
                        src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                        alt="img"
                      />

                      <div className="d-lg-flex flex-column ms-5">
                        <span style={customFont}>{x.Name}</span>
                        <span style={customFont}>Size: small</span>
                        <span style={customFont}>Price: {x.ItemPrice}</span>
                        <span style={customFont}>Quantity: {x.Quantity}</span>
                      </div>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                      <span
                        onClick={() => handleQuantityChange(index, -1)}
                        style={{ fontSize: "25px" }}
                      >
                        -
                      </span>
                      <span style={{ fontSize: "25px" }} className="px-3">
                        {x.Quantity}
                      </span>
                      <span
                        onClick={() => handleQuantityChange(index, 1)}
                        style={{ fontSize: "25px" }}
                      >
                        +
                      </span>
                    </div>
                  </div>

                  <span>Modifiers</span>
                  {x.Modifiers?.map((modifier, index) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <div>
                          <span>{modifier.Title}</span>
                        </div>

                        <div>
                          <span>{modifier.Price}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}

          <div className="col-lg-4">
            <div style={{ position: "absolute", top: "50px", width: "30%" }}>
              <div className="shadow p-lg-3">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      type="text"
                      placeholder="Enter your name"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Special Instructions (Optional)</Form.Label>
                    <Form.Control
                      value={specialInstructions}
                      onChange={(e) => setSpecialInstructions(e.target.value)}
                      as={"textarea"}
                      rows={3}
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number (Optional)</Form.Label>
                    <Form.Control
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="number"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Payment Method</Form.Label>
                    <Form.Select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option disabled>Select Payment Method</option>
                      <option value="Card">Card</option>
                      <option value="Cash">Cash</option>
                    </Form.Select>
                  </Form.Group>

                  <div className="d-flex align-items-center justify-content-between">
                    <span>Sub Total</span>
                    <span>{orderDetail.total_Price}</span>
                  </div>
                </Form>

                <div className="d-flex justify-content-center">
                  <button onClick={handler} className="global-btn mb-2">
                    confirm
                  </button>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
