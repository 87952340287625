import React, { useState } from "react";
import data from "../Data.json";
import { useNavigate } from "react-router-dom";
import Cart from "./Cart";
import { MenuState } from "../context/MenuContext";

const ItemCards = (props) => {
  const { cartResponse, setCartResponse } = MenuState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const itemData = data?.foodItems
    .filter((item) => item.groupId === props?.sectionId)
    .map((x) => x);

  const goToItemDetail = (detail) => {
    navigate("/menu-detail", { state: { detail } });
  };

  // const handleShow = (newData) => {

  //   const updatedCart = [...cartResponse, newData];
  //   setCartResponse(updatedCart);

  // };

  const handleShow = (newData) => {
    let itemExists = false;

    const updatedCart = cartResponse?.map((item) => {
      if (item.id === newData.id) {
        itemExists = true;
        const newQuantity = (item.quantity || 1) + 1;
        const newTotalPrice = item.originalPrice * newQuantity;
        return { ...item, quantity: newQuantity, totalPrice: newTotalPrice };
        //return { ...item, quantity: (item.quantity || 1) + 1 };
      }
      return item;
    });

    if (!itemExists) {
      updatedCart.push({
        ...newData,
        quantity: 1,
        originalPrice: newData.price,
        totalPrice: newData.price,
      });
    }

    setCartResponse(updatedCart);
    //console.log(cartResponse, "cartResponse");
  };

  return (
    <div>
      <Cart show={show} onHide={handleClose} />
      <div className="text-center pt-5">
        {/* <h3>{sectionData?.groupName}</h3> */}
        {/* <p className="m-0">hardcode Description</p> */}
      </div>
      {itemData?.map((x, index) => {
        return (
          <div key={index} id="section-items">
            <div className="d-flex flex-column bg-white shadow p-4 mt-4 position-relative">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="food-card">
                  <h4
                    onClick={() => goToItemDetail(x)}
                    style={{ cursor: "pointer", width: "98%" }}
                  >
                    {x.itemName}
                  </h4>
                  {/* <p className="food-description m-0 pt-2">
                    Sriracha Loaded Fries: Spicy, savory, and satisfying potato
                    perfection
                  </p> */}
                  <p className="m-0 pt-2">Price {x.price}</p>
                </div>
                <div>
                  {/* <img className="custom-card-img" src={x.image} alt="kpg" /> */}
                  <img
                    className="custom-card-img"
                    src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                    alt="kpg"
                  />
                </div>
              </div>
              <button onClick={() => handleShow(x)} className="global-btn mt-4">
                Add to card
              </button>

              <div className="rotate-btn text-white text-center fs-3">
                <span onClick={() => handleShow(x)}>+</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ItemCards;
