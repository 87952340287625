import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuState } from "../context/MenuContext";
import { MdDelete } from "react-icons/md";

const Cart = (props) => {
  const location = useLocation();
  const {
    cartResponse,
    setCartResponse,
    modifiersResponse,
    setModifiersResponse,
    orderDetail,
    setOrderDetail,
    cartData,
    setCartData,
  } = MenuState();

  //console.log(orderDetail, "from cart orderdetail");
  // const [cartData, setCartData] = useState({
  //   cartItems: [],
  //   total_Price: 0,
  // });

  useEffect(() => {
    const updatedCartItems = cartResponse?.map((item) => ({
      Id: item.id,
      Name: item.itemName,
      ItemPrice: item.price,
      Quantity: item.quantity,
      TotalPrice: item.totalPrice,
      Image: item.image,
      Modifiers: [],
    }));

    setCartData((prevData) => ({
      ...prevData,
      cartItems: updatedCartItems,
      total_Price: updatedCartItems.reduce(
        (total, item) => total + item.TotalPrice,
        0
      ),
    }));
  }, [cartResponse]);

  //console.log(cartResponse, "cartResponse");

  const handleQuantityChange = (index, delta) => {
    // const newCartData = { ...cartData };
    // newCartData.cartItems[index].Quantity += delta;

    // // Prevent negative quantity
    // if (newCartData.cartItems[index].Quantity < 1) {
    //   newCartData.cartItems[index].Quantity = 1;
    // } else {
    //   newCartData.cartItems[index].TotalPrice =
    //     newCartData.cartItems[index].ItemPrice *
    //     newCartData.cartItems[index].Quantity;
    // }

    // // Update subtotal
    // newCartData.total_Price = newCartData.cartItems.reduce(
    //   (total, item) => total + item.TotalPrice,
    //   0
    // );

    // setCartData(newCartData);
    const newCartData = { ...cartData };
    let item = newCartData.cartItems[index];

    // Increment or decrement the item quantity
    item.Quantity += delta;

    // Prevent negative quantity
    if (item.Quantity < 1) {
      item.Quantity = 1;
    }

    // Calculate the total price of the modifiers, if any
    const modifiersPrice = item.Modifiers
      ? item.Modifiers.reduce((acc, modifier) => acc + modifier.Price, 0)
      : 0;

    // Update the TotalPrice of the item to include the modifier's price
    item.TotalPrice = item.ItemPrice * item.Quantity + modifiersPrice;

    // Update subtotal
    newCartData.total_Price = newCartData.cartItems.reduce(
      (total, item) => total + item.TotalPrice,
      0
    );

    setCartData(newCartData);
  };

  const handleDeleteItem = (index, itemId) => {
    const newCartData = { ...cartData };
    // Remove the item from the cartItems array
    newCartData.cartItems.splice(index, 1);

    // Update subtotal after removing the item
    newCartData.total_Price = newCartData.cartItems.reduce(
      (total, item) => total + item.ItemPrice * item.Quantity,
      0
    );
    const newCartResponse = cartResponse.filter((item) => item.id !== itemId);

    setCartData(newCartData);
    setCartResponse(newCartResponse);
  };

  // console.log(cartData.cartItems.length, "cart data");
  const navigate = useNavigate();

  const goToOrderPage = () => {
    setOrderDetail(cartData);
    navigate("/order-page");
  };

  console.log(cartData, "cartData");

  return (
    <Offcanvas placement="end" show={props.show} onHide={props.onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Your Cart</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {cartData?.cartItems?.map((item, index) => {
          return (
            <>
              <div
                className="shadow p-lg-3 d-flex align-items-start justify-content-between"
                key={index}
              >
                <div>
                  {item.image ? (
                    <img
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                        boxShadow: "silver 0.3px 0.3px 5px",
                      }}
                      src={item.image}
                      alt="img"
                    />
                  ) : (
                    <img
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                        boxShadow: "silver 0.3px 0.3px 5px",
                      }}
                      src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                      alt="img"
                    />
                  )}
                </div>

                <div className="d-flex flex-column">
                  <span>{item.Name}</span>

                  <div style={{ cursor: "pointer" }}>
                    <span onClick={() => handleQuantityChange(index, -1)}>
                      -
                    </span>
                    <span className="px-3">{item.Quantity}</span>
                    <span onClick={() => handleQuantityChange(index, 1)}>
                      +
                    </span>
                    {/* <span onClick={() => handleDeleteItem(index, item.Id)}>
                    Delete
                  </span> */}
                    <MdDelete
                      className="ms-2"
                      size={20}
                      color="#c00"
                      onClick={() => handleDeleteItem(index, item.Id)}
                    />
                  </div>
                </div>

                <div>
                  <span>PKR</span>
                  <span>{item.ItemPrice.toFixed(2)}</span>
                </div>
              </div>

              <span>Modifiers</span>
              {item.Modifiers?.map((modifier, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center pt-3">
                    <div>
                      <span>{modifier.Title}</span>
                    </div>

                    <div>
                      <span>{modifier.Price}</span>
                    </div>
                  </div>
                );
              })}

              <hr />
            </>
          );
        })}

        {/* ---FOR MODIFIER--- */}
        {/* <div className="shadow p-lg-3 ">
          <div className="d-flex align-items-start justify-content-between mt-4">
            <div>
              <img
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "5px",
                  boxShadow: "silver 0.3px 0.3px 5px",
                }}
                src="https://res.cloudinary.com/dkq6jers7/image/upload/v1693565265/acb7rzpbkoyqsapkveqv.png"
                alt="img"
              />
            </div>

            <div className="d-flex flex-column">
              <span>Protein Style Burger</span>
              <span>Small</span>

              <div style={{ cursor: "pointer" }}>
                <span>-</span>
                <span className="px-3">1</span>
                <span>+</span>
              </div>
            </div>

            <div>
              <span>PKR</span>
              <span>558</span>
            </div>
          </div>
          <span>Modifiers</span>

          <div className="d-flex justify-content-between align-items-center pt-3">
            <div>
              <span>Toy Combo</span>
            </div>

            <div style={{ cursor: "pointer" }}>
              <span>-</span>
              <span className="px-3">1</span>
              <span>+</span>
            </div>

            <div>
              <span>PKR 150</span>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span>Toy Combo</span>
            </div>

            <div style={{ cursor: "pointer" }}>
              <span>-</span>
              <span className="px-3">1</span>
              <span>+</span>
            </div>

            <div>
              <span>PKR 150</span>
            </div>
          </div>
          <hr />
        </div> */}
      </Offcanvas.Body>

      <div className="container">
        <hr />

        <div className="d-flex justify-content-between">
          <span>Total</span>
          <span>PKR {cartData.total_Price.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-center">
          {location.pathname === "/order-page" ? null : (
            <button onClick={goToOrderPage} className="global-btn mb-2">
              Contine Your Cart
            </button>
          )}
        </div>
      </div>
    </Offcanvas>
  );
};

export default Cart;
