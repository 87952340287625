import React from "react";
import { CiShoppingCart } from "react-icons/ci";
import { IoIosArrowRoundBack } from "react-icons/io";

const CustomHeader = ({ onBackClick, onCartIconClick, cartQty, hideCart }) => {
  return (
    <div className="custom-header d-flex justify-content-between align-items-center">
      {!onBackClick ? (
        <div></div>
      ) : (
        <IoIosArrowRoundBack
          onClick={onBackClick}
          style={{ cursor: "pointer" }}
          size={40}
        />
      )}
      {hideCart ? null : (
        <div className="d-flex align-items-center">
          <CiShoppingCart
            onClick={onCartIconClick}
            style={{ cursor: "pointer" }}
            size={30}
          />
          <span style={{ fontSize: "20px" }} className="ms-2">
            {cartQty}
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomHeader;
